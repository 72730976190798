// Before the fix GO-14092 tenant urls were using
// tenantLabel instead of the slug. Redirects required
// for preventing old urls from becoming dead links
import { getBasePath } from './route'

export const tenantSlugRedirects: Record<string, string> = {
  bact: 'basketball-act',
  btas: 'basketball-tasmania',
  bv: 'basketball-victoria',
  ca: 'cricket-australia',
  na: 'netball-australia',
  // nsw-basketball tenant has been updated to au-basketball GO-30039
  nswbball: 'au-basketball',
  'nsw-basketball': 'au-basketball',
}

export const getTenantServiceName = <T>(tenantSlug: T): T | string => {
  // Maps au-basketball tenant to nsw-basketball tenant GO-30039
  // This is because the services for au-basketball were created as nsw-basketball in the backend
  if (
    typeof tenantSlug === 'string' &&
    (tenantSlug === 'au-basketball' || tenantSlug === 'aubball')
  ) {
    return 'nsw-basketball'
  }
  return tenantSlug
}

interface Tenant {
  name: string
  slug: string
}

export const TENANTS: Tenant[] = [
  { name: 'AFL', slug: 'afl' },
  { name: 'Basketball ACT', slug: 'basketball-act' },
  { name: 'Basketball Tasmania', slug: 'basketball-tasmania' },
  { name: 'Basketball Victoria', slug: 'basketball-victoria' },
  { name: 'Basketball WA', slug: 'basketball-wa' },
  { name: 'Cricket Australia', slug: 'cricket-australia' },
  { name: 'Football Australia', slug: 'football-au' },
  { name: 'Hockey New Zealand', slug: 'hockey-new-zealand' },
  { name: 'Netball Australia', slug: 'netball-australia' },
  { name: 'New Zealand Cricket', slug: 'new-zealand-cricket' },
  { name: 'AU Basketball', slug: 'au-basketball' },
]

/**
 * Extracts the tenant slug from the current URL path.
 * Matches the pattern /:tenant/*, where :tenant is the slug.
 */
export const getTenantFromUrl = () => {
  const basePath = getBasePath()
  const tenantRegex = new RegExp(
    `^(${basePath ? `\\${basePath}` : ''})?\\/([a-zA-Z-]+)+`,
  )
  // Matching /:tenant/*.
  // Yes it will match /account as well, but that should not matter at all
  const match =
    typeof window !== 'undefined'
      ? window.location.pathname.match(tenantRegex)
      : null
  // tenant will come second after basepath
  return match && match.at(-1)
}
